<template>
  <div class="row mt-4">
    <div class="col-12">
      <material-input
        id="title"
        v-model="title"
        is-required
        variant="static"
        label="Titel"
        placeholder="Vul een titel in"
      />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <rich-text-editor
        id="description"
        v-model:content="description"
        title="Omschrijving"
      />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <material-input
        id="videoUrl"
        v-model="videoUrl"
        variant="static"
        label="Video"
        placeholder="URL"
      />
    </div>
  </div>

  <date-time-picker
    v-model="publishDateTime"
    label="Gepubliceerd op"
  />

  <material-button
    v-if="showUpdate"
    class="float-end mt-6 mb-0"
    color="dark"
    variant="gradient"
    size="sm"
  >
    Update gegevens
  </material-button>
</template>

<script>
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import RichTextEditor from '@/components/UI/RichTextEditor.vue';
import DateTimePicker from '@/components/UI/DateTimePicker.vue';

export default {
  name: 'BasicInfo',

  components: {
    MaterialButton,
    MaterialInput,
    DateTimePicker,
    RichTextEditor
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  emits: [
    'update:modelValue',
  ],

  computed: {
    title: {
      get() { return this.modelValue.title; },
      set(value) {
        this.$emit('update:modelValue', { ...this.modelValue, title: value });
      }
    },

    description: {
      get() { return this.modelValue.description; },
      set(value) {
        this.$emit('update:modelValue', { ...this.modelValue, description: value });
      }
    },

    videoUrl: {
      get() { return this.modelValue.videoUrl; },
      set(value) { 
        this.$emit('update:modelValue', { ...this.modelValue, videoUrl: value });
      }
    },

    publishDateTime: {
      get() { return this.modelValue.publishDate; },
      set(value) { 
        this.$emit('update:modelValue', { ...this.modelValue, publishDate: value });
      }
    }
  }
};
</script>