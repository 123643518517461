import createImageGalleryApi from './provider';
import { postRequest } from '../..';

const PROJECT_ITEM_ENTITY_TYPE = 2;

let { get, add, update, remove } = createImageGalleryApi(PROJECT_ITEM_ENTITY_TYPE);

get = (articleId) => postRequest(`/photogallery/projectitems/${articleId}/1`, {
    currentPage: 0,
    pageSize: 9999
});

export { get, add, update, remove };