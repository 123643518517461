<template>
  <div class="row mt-4">
    <div class="col-6">
      <material-input
        id="publish-date"
        v-model="date"
        :label="label"
        is-required
        variant="static"
        type="date"
      />
    </div>

    <div class="col-6">
      <material-input
        id="publish-time"
        v-model="time"
        label="&nbsp;"
        is-required
        variant="static"
        type="time"
      />
    </div>
  </div>
</template>

<script>
import MaterialInput from '@/components/MaterialInput.vue';
import { getDateString, getTimeString } from '@/helpers/datetime';

export default {
  name: 'DateTimePicker',

  components: {
    MaterialInput
  },

  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },

  emits: [
    'update:modelValue',
  ],

  computed: {
    date: {
      get() { return getDateString(new Date(this.modelValue)); },
      set(value) {
        this.$emit(
          'update:modelValue',
          new Date(`${value} ${this.time}`).toISOString()
        );
      }
    },

    time: {
      get() { 
        return getTimeString(new Date(this.modelValue));
      },

      set(value) {
        this.$emit(
          'update:modelValue',
          new Date(`${this.date} ${value}`).toISOString()
        );
      }
    },
  },

  mounted() {
    if (this.modelValue === "") {
      this.$emit(
        'update:modelValue',
        new Date().toISOString()
      );
    }
  },
};
</script>